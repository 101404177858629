<script>

import {extensions} from '../data/extensions'

export default {
  data: function () {
    return {
      allDataValid: false,
      validContactInfo: false,
      validNotificationEmail: false,
      addContactInfo: this.$store.state.addContactInfo,
      addNotificationEmail: this.$store.state.addNotificationEmail,
      contactName: this.$store.state.contactName,
      contactEmail: this.$store.state.contactEmail,
      contactPhone: this.$store.state.contactPhone,
      notificationEmail: this.$store.state.notificationEmail,
      inputRules: [
        v => !!v || 'contactInfo.emptyFieldAlert'
      ],
      emailRules: [
        v => !!v || 'contactInfo.emptyFieldAlert',
        v => /.+@.+\..+/.test(v) || 'contactInfo.checkEmail',
      ],
      fileList: [],
      fromData: new FormData()
    }
  },
  methods: {
    toggleAddNotificationEmail: function () {
      this.notificationEmail = ''
      this.$store.commit("updateNotificationEmail", this.notificationEmail)
      this.$store.commit("toggleAddNotificationEmail")
    },
    setContactInfo: function () {
      this.$store.commit("updateContactInfo",
          {
            name: this.contactName,
            email: this.contactEmail,
            phone: this.contactPhone
          }
      );
    },
    setNotificationEmail: function () {
      this.$store.commit("updateNotificationEmail", this.notificationEmail)
    },
    selectFile: function (file) {
      let index = file[0].name.lastIndexOf(".")
      let extension = file[0].name.substring(index)
      if (extensions.includes(extension)) {
        this.fileList.push(file)
        this.$store.commit("updateAddedFiles", file)
      } else {
        this.$store.commit("setSnack", {
          text: this.$i18n.locale == 'fi' ? "Tiedostomuoto ei sallittu!" : "File format not allowed!",
          message: "",
          color: "error",
        });
      }
    },
    deleteFile: function (index) {
      this.$store.commit("deleteFile", index)
    }
  },
  computed: {
    nextAllowed: function () {
      if (!this.addNotificationEmail) {
        return this.contactName !== "" && (this.contactPhone !== "" || this.contactEmail !== "")
      }
      return (this.contactName !== "" && (this.contactPhone !== "" || this.contactEmail !== "")) && this.validNotificationEmail;
    },
  },
  mounted() {
	if (!this.$store.state.addContactInfo) {
	  this.$store.commit("toggleAddContactInfo")
	}
  }
}
</script>

<template>

  <div>
    <v-row>
      <v-col>
        <div class="d-flex" style="align-items: center;">
          <v-img height="1rem" src="/img/icons/info-circle.svg" style="flex-grow: 0; margin-right: 5px" width="1rem"/>
          <p class="font-weight-bold my-0">{{ $t('contactInfo.contactRequiredInfo') }}</p>
        </div>
        <v-form v-model="validContactInfo">
          <v-row>
            <v-col>
              <label for="huolenaihe">{{ $t('contactInfo.name') }}</label>
              <v-text-field
                  id="huolenaihe"
                  v-model="contactName"
                  :rules="inputRules"
                  background-color="#EDEDEF"
                  class="pt-0"
                  flat
                  hide-details
                  solo
                  @input="setContactInfo">
                <template #message="{ message }">{{ $t(message) }}</template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="huolenaihe">{{ $t('contactInfo.email') }}</label>
              <v-text-field
                  id="huolenaihe"
                  v-model="contactEmail"
                  :rules="emailRules"
                  background-color="#EDEDEF"
                  class="pt-0 mb-0"
                  flat
                  hide-details="auto"
                  solo
                  @input="setContactInfo">
                <template #message="{ message }">{{ $t(message) }}</template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="huolenaihe">{{ $t('contactInfo.phone') }}</label>
              <v-text-field
                  id="huolenaihe"
                  v-model="contactPhone"
                  :rules="inputRules"
                  background-color="#EDEDEF"
                  class="pt-0"
                  flat
                  hide-details
                  solo
                  type="number"
                  @input="setContactInfo">
                <template #message="{ message }">{{ $t(message) }}</template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
            v-model="addNotificationEmail"
            :label="$t('contactInfo.subscribeNotificationsBox')"
            class="black--text"
            color="#000"
            @click="toggleAddNotificationEmail"
        ></v-checkbox>
        <v-form v-if="addNotificationEmail" v-model="validNotificationEmail">
          <v-row>
            <v-col>
              <label for="sähköpostiosoite">{{ $t('contactInfo.emailForNotifications') }}</label>
              <v-text-field
                  id="sähköpostiosoite"
                  v-model="notificationEmail"
                  :rules="emailRules"
                  background-color="#EDEDEF"
                  class="pt-0"
                  flat
                  hide-details="auto"
                  solo
                  @input="setNotificationEmail">
                <template #message="{ message }">{{ $t(message) }}</template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex">
          <v-img height="1rem" src="/img/icons/info-circle.svg" style="flex-grow: 0; margin-top: 3px; margin-right: 5px"
                 width="1rem"/>
          <p class="font-weight-bold my-0">{{ $t('contactInfo.attachmentsInfo') }}</p>
        </div>
        <div>
          <v-file-input
              :label="$t('contactInfo.addFile')"
              multiple
              truncate-length="10"
              @change="selectFile"
          >
          </v-file-input>
        </div>
        <v-card class="mx-auto">
          <v-list>
            <v-subheader>{{ $t('contactInfo.addedFiles') }}</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(file, index) in this.$store.state.addedFiles" :key="index">
                <a :href="file[0].url">{{ file[0].name }}</a>
                <button aria-label="Close" class="close" type="button" @click="deleteFile(index)">
                  <span aria-hidden="true">×</span>
                </button>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
        :to="`/${this.$store.state.organization.urlName}/lomake`"
        class="my-8"
        color="#424752"
        depressed
        style="color: #fff; font-size: 1rem;position: absolute; left: 12px;">
      {{ $t('general.previous') }}
    </v-btn>
    <v-btn
        :disabled="!nextAllowed"
        :to="`/${this.$store.state.organization.urlName}/yhteenveto`"
        class="my-8"
        color="#424752"
        depressed
        style="color: #fff; font-size: 1rem;position: absolute; right: 12px;">
      {{ $t('general.next') }}
    </v-btn>
  </div>

</template>

<style scoped>
.black--text /deep/ label {
  color: black;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>